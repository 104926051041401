import React from 'react'

import { Default as Notices } from '@arch-log/webapp.shared/Notices'

import * as Containers from './Containers'
import * as Layout from './Layout'

/**
 */
export const Default = ({ next, context }) => {
  return (
    <Containers.Initializer
      onSubmit={(data) => next({ formData: data })}
      defaultValues={context?.defaultValues}
      values={context?.formData}
      errors={context?.errors}
    >
      <Notices />
      <Layout.Wrapper>
        <Layout.Header>
          <Containers.Title />
        </Layout.Header>
        <Layout.Body>
          <Containers.FormErrors />
          <Containers.FormFields />
        </Layout.Body>
        <Layout.Footer>
          <Containers.FormButtons />
        </Layout.Footer>
      </Layout.Wrapper>
    </Containers.Initializer>
  )
}
