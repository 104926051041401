import React from 'react'

import * as styles from './styles.module.scss'

/**
 */
export const Title = ({ children }) => (
  <span className={styles.Title}> {children}</span>
)

/**
 */
export const Buttons = ({ children }) => (
  <div className={styles.ButtonContainer}>{children}</div>
)
