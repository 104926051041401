import React from 'react'

import { useFlow } from '@arch-log/webapp.atomics/components/Flow'

/**
 */
const defaultFormValues = {
  buildingExtended: {
    certifications: {
      otherCertifications: new Array(5).fill({}),
    },
    periodicInspections: new Array(5).fill({}),
  },
  images: new Array(10).fill({}),
}

/**
 */
export const Default = () => {
  const { next } = useFlow()

  React.useEffect(() => {
    next({ defaultValues: { ...defaultFormValues } })
  }, [])

  return null
}
