import React from 'react'

import * as Literals from './Literals'
import * as Styled from './Styled'

/**
 */
export const Message = () => (
  <Styled.Message>
    <Literals.Message />
  </Styled.Message>
)

/**
 */
export const CompleteButton = ({ onComplete }) => {
  return (
    <Styled.CompleteButton onComplete={onComplete}>
      <Literals.CompleteButton />
    </Styled.CompleteButton>
  )
}
