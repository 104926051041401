import React from 'react'

import { useNavigate } from '@arch-log/webapp.lib/react.hook'

import * as PageHeadTemplates from '@arch-log/webapp.shared/Page/Head'
import * as Routes from '@arch-log/webapp.shared/Routes'
import * as Partial from 'partials/ProjectNewPage'

/**
 */
export const Head = ({ pageContext }) => {
  return <PageHeadTemplates.Language language={pageContext?.language} />
}

/**
 */
const Page = () => {
  const { redirectTo } = useNavigate()

  return (
    <Partial.Flow
      onComplete={({ id }) => {
        redirectTo(Routes.Project.toSetting({ project: id }))
      }}
    />
  )
}

export default Page
