import { translated } from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const Message = translated('app.project')(
  'ProjectNew.Flow.CompleteStep.Message',
)

/**
 */
export const CompleteButton = translated('app.project')(
  'ProjectNew.Flow.CompleteStep.CompleteButton',
)
