import React from 'react'

import * as Project from '@arch-log/webapp.modules/project.v2/components'
import * as Links from '@arch-log/webapp.shared/Links'
import * as ProjectForm from 'components/ProjectForm'
import * as Styled from './Styled'
import * as Literals from './Literals'

import * as styles from './styles.module.scss'

/**
 */
export const Title = () => (
  <Styled.Title>
    <Literals.Title />
  </Styled.Title>
)

/**
 */
export const Initializer = ({
  onSubmit,
  defaultValues,
  values,
  errors,
  children,
}) => {
  const {
    structureTypeOptions,
    areaTypeOptions,
    applicableAreaOptions,
    fireZoneTypeOptions,
    zoneUsageTypeOptions,
    basisTypeOptions,
    certificationTypeOptions,
    propertyTypeOptions,
    constructionAreaOptions,
    constructionTypeOptions,
    japanesePrefectureOptions,
  } = Project.Remote.Queries.PreFormData.useConstsData()

  return (
    <Project.Form.Initializer
      defaultValues={defaultValues}
      values={values}
      onSubmit={onSubmit}
      errors={errors}
      structureTypeOptions={structureTypeOptions}
      areaTypeOptions={areaTypeOptions}
      applicableAreaOptions={applicableAreaOptions}
      fireZoneTypeOptions={fireZoneTypeOptions}
      zoneUsageTypeOptions={zoneUsageTypeOptions}
      basisTypeOptions={basisTypeOptions}
      certificationTypeOptions={certificationTypeOptions}
      propertyTypeOptions={propertyTypeOptions}
      constructionAreaOptions={constructionAreaOptions}
      constructionTypeOptions={constructionTypeOptions}
      japanesePrefectureOptions={japanesePrefectureOptions}
    >
      {children}
    </Project.Form.Initializer>
  )
}
/**
 */
export const FormErrors = ({ ...props }) => {
  const { formatError } = Literals.useEmbedded()

  return <Project.Form.FormError formatMessage={formatError} {...props} />
}

/**
 */
export const FormButtons = ({
  components: { Wrapper = Styled.Buttons } = {},
}) => (
  <Wrapper>
    <Submit />
    <Back />
  </Wrapper>
)

/**
 */
export const FormFields = ProjectForm.Default

/**
 */
export const Back = ({ ...props }) => {
  return (
    <Links.Project.ToProjectList className={styles.BackButton} {...props}>
      <Literals.Cancel />
    </Links.Project.ToProjectList>
  )
}

/**
 */
export const Submit = () => (
  <Project.Form.Submit className={styles.SubmitButton}>
    <Literals.Submit />
  </Project.Form.Submit>
)
