import React from 'react'

import * as styles from './styles.module.scss'

/**
 */
export const Message = ({ children, ...props }) => {
  return (
    <p className={styles.Message} {...props}>
      {children}
    </p>
  )
}

/**
 */
export const CompleteButton = ({ children, onComplete, ...props }) => {
  return (
    <button
      type="button"
      className={styles.CompleteButton}
      onClick={() => onComplete()}
      {...props}
    >
      {children}
    </button>
  )
}
