import React from 'react'

import {
  translated,
  useTranslation,
} from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const Title = translated('app.project')('ProjectNew.Title')

/**
 */
export const Submit = translated('app.project')('ProjectNew.Submit')

/**
 */
export const Cancel = translated('app.project')('ProjectNew.Cancel')

/**
 */
export const useEmbedded = () => {
  const { t } = useTranslation(['module.project'])

  const formatError = t

  return {
    formatError,
  }
}
